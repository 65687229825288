var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{ref:"refScoresList",staticClass:"table-new-customization custom-table",staticStyle:{"margin-bottom":"0px"},attrs:{"items":_vm.allScores,"fields":_vm.fields,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","sticky-header":(_vm.stickySize + "vh"),"busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("myGlobal")(data.item.date))+" ")]}},{key:"cell(file)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[(data.item.route_pdf != null)?_c('a',{attrs:{"href":data.item.route_pdf,"target":"_blanck"}},[_c('amg-icon',{staticClass:"text-danger",attrs:{"size":"18","icon":"FilePdfIcon"}})],1):_c('span',[_vm._v("-- ")])])]}},{key:"cell(cr)",fn:function(data){return [(data.item.state == 1)?_c('router-link',{attrs:{"target":"_blank","to":{
        name: 'ce-customer-service-report-lead',
        params: {
          idfile: data.item.score_id,
          idlead: data.item.lead_id,
          accountId: _vm.client.id,
          modul: 25,
        },
      }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"15"}})],1):_vm._e()]}},{key:"cell(equifax)",fn:function(data){return [_c('div',[_c('span',{staticStyle:{"width":"20px","color":"#981e32","font-weight":"bold","font-size":"15px","border-top":"none","text-align":"center"}},[_vm._v(_vm._s(data.item.equifax)+" ")])])]}},{key:"cell(experian)",fn:function(data){return [_c('span',{staticStyle:{"color":"#0566b7","font-weight":"bold","font-size":"15px","border-top":"none","text-align":"center"}},[_vm._v(_vm._s(data.item.experian)+" ")])]}},{key:"cell(trans_union)",fn:function(data){return [_c('span',{staticStyle:{"color":"#4d917a","font-weight":"bold","font-size":"15px","border-top":"none","text-align":"center"}},[_vm._v(_vm._s(data.item.transunion)+" ")])]}},{key:"cell(deleted_ac)",fn:function(data){return [_vm._v(" "+_vm._s(((data.item.deleted_ac) + " / " + (data.item.updated_ac)))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }