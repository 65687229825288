import { amgApi } from '@/service/axios';

class ClientReportService {
  constructor() {
    this.routeReport = "clients/reports";
  }

  // async getClientReport(params) {
  //   try {
  //     const { data } = await amgApi.post("clients/reports/get-client-report", params)
  //     return data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  async getScores(params) {
    try {
      const { data } = await amgApi.post(`${this.routeReport}/get-scores`, params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getPersonalInformation(params) {
    try {
      const { data } = await amgApi.post(`${this.routeReport}/get-personal-information`, params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getPersonalInformationByScore(params){
    try {
      const { data } = await amgApi.post(`${this.routeReport}/get-personal-information-by-score`, params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getAccountHistory(params) {
    try {
      const { data } = await amgApi.post(`${this.routeReport}/get-account-history`, params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getOnlyAccountsInDispute(params) {
    try {
      const { data } = await amgApi.post(`${this.routeReport}/get-only-accounts-in-dispute`, params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }


  async getPublicRecords(params) {
    try {
      const { data } = await amgApi.post(`${this.routeReport}/get-public-records`, params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getPublicRecordsByScore(params) {
    try {
      const { data } = await amgApi.post(`${this.routeReport}/get-public-records-by-score`, params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getInquiries(params) {
    try {
      const { data } = await amgApi.post(`${this.routeReport}/get-inquiries`, params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getInquiriesByScore(params) {
    try {
      const { data } = await amgApi.post(`${this.routeReport}/get-inquiries-by-score`, params);
      return data;
    } catch (error) {
      throw error;
    }
  }


  async getTrackingReportDetails(params) {
    try {
      const { data } = await amgApi.post(`${this.routeReport}/get-tracking-report-details`, params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

}

export default new ClientReportService();
