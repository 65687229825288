var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-grid mb-2 px-1",class:{ small: _vm.small }},[_c('div',{staticClass:"shadow w-auto rounded text-center",class:_vm.small ? 'small-border' : 'p-3'},[_c('div',{staticClass:"d-flex align-items-center justify-content-center",style:(_vm.small ? 'height: 32px' : 'height: 64px')},[_c('b-img',{attrs:{"src":'/assets/images/icons/bureaus/transunion.png',"width":_vm.small ? 64 : 98}})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('h2',{staticClass:"item-number",class:{ small: _vm.small }},[_vm._v(" "+_vm._s(_vm.newScores.transunion == "Score Not Reported" ? "N/A" : _vm.newScores.transunion)+" ")]),(_vm.oldScores)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("Last Score: " + (_vm.oldScores.transunion))),expression:"`Last Score: ${oldScores.transunion}`",modifiers:{"hover":true,"top":true}}],staticClass:"ml-1",class:{
          'text-success': _vm.oldScores.transunion < _vm.newScores.transunion,
          'text-danger': _vm.oldScores.transunion > _vm.newScores.transunion,
        },attrs:{"icon":_vm.oldScores.transunion < _vm.newScores.transunion
            ? 'TrendingUpIcon'
            : 'TrendingDownIcon',"size":_vm.small ? '18' : '28'}}):_vm._e()],1)]),_c('div',{staticClass:"shadow w-auto rounded text-center",class:_vm.small ? 'small-border' : 'p-3'},[_c('div',{staticClass:"d-flex align-items-center justify-content-center",style:(_vm.small ? 'height: 32px' : 'height: 64px')},[_c('b-img',{attrs:{"src":'/assets/images/icons/bureaus/experian.png',"width":_vm.small ? 64 : 98}})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('h2',{staticClass:"item-number",class:{ small: _vm.small }},[_vm._v(" "+_vm._s(_vm.newScores.experian == "Score Not Reported" ? "N/A" : _vm.newScores.experian)+" ")]),(_vm.oldScores)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("Last Score: " + (_vm.oldScores.experian))),expression:"`Last Score: ${oldScores.experian}`",modifiers:{"hover":true,"top":true}}],staticClass:"ml-1",class:{
          'text-success': _vm.oldScores.experian < _vm.newScores.experian,
          'text-danger': _vm.oldScores.experian > _vm.newScores.experian,
        },attrs:{"icon":_vm.oldScores.experian < _vm.newScores.experian
            ? 'TrendingUpIcon'
            : 'TrendingDownIcon',"size":_vm.small ? '18' : '28'}}):_vm._e()],1)]),_c('div',{staticClass:"shadow w-auto rounded text-center",class:_vm.small ? 'small-border' : 'p-3'},[_c('div',{staticClass:"d-flex align-items-center justify-content-center",style:(_vm.small ? 'height: 32px' : 'height: 64px')},[_c('b-img',{attrs:{"src":'/assets/images/icons/bureaus/equifax.png',"width":_vm.small ? 64 : 98}})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('h2',{staticClass:"item-number",class:{ small: _vm.small }},[_vm._v(" "+_vm._s(_vm.newScores.equifax == "Score Not Reported" ? "N/A" : _vm.newScores.equifax)+" ")]),(_vm.oldScores)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("Last Score: " + (_vm.oldScores.equifax))),expression:"`Last Score: ${oldScores.equifax}`",modifiers:{"hover":true,"top":true}}],staticClass:"ml-1",class:{
          'text-success': _vm.oldScores.equifax < _vm.newScores.equifax,
          'text-danger': _vm.oldScores.equifax > _vm.newScores.equifax,
        },attrs:{"icon":_vm.oldScores.equifax < _vm.newScores.equifax
            ? 'TrendingUpIcon'
            : 'TrendingDownIcon',"size":_vm.small ? '18' : '28'}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }