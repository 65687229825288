<template>
  <div
    v-if="newScores"
    class="mb-2 px-5 py-1"
    :class="isDarkSkin ? 'dark-bg-card' : 'light-bg-card'"  
  >
    <div @click="iconIsDown = !iconIsDown">
      <div class="d-flex justify-content-between">
        <h4 class="m-0 p-0 font-weight-bolder">SCORE</h4>
        <span class="cursor-pointer text-dark" style="margin-top: 2px">
          <tabler-icon
            :icon="iconIsDown ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            size="20"
          />
        </span>
      </div>
      <hr v-if="iconIsDown" class="text-dark" style="border-top: 3px groove" />
    </div>
    <b-collapse id="collapse-score-report" v-model="iconIsDown" class="rounded">
      <score-burous />
      <AllScores :lead_id="newScores.lead_id" :stickySize="60" />
    </b-collapse>
  </div>
</template>

<script>
// Service Client Report
import ClientReportService from "@/views/commons/components/clients/dashboard/services/client.report.js";
import AllScores from "@/views/commons/components/clients/dashboard/options/report-module/components/AllScores.vue";
import ScoreBurous from "@/views/commons/components/clients/dashboard/options/report-module/components/ScoreBurous.vue";
export default {
  components: {
    AllScores,
    ScoreBurous,
  },
  props: {
    clientId: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAllReports: false,
      dataScores: [],
      iconIsDown: true,
    };
  },
  async created() {
    await this.getClientReport();
  },
  computed: {
    newScores() {
      return this.dataScores[0] || null;
    },
    oldScores() {
      return this.dataScores[1] || null;
    },
  },
  methods: {    
    async getClientReport() {
      try {
        this.loading = true;
        let params = {
          idClientAccount: this.$route.params.idClient || this.clientId,
        };
        const { data } = await ClientReportService.getScores(params);
        this.dataScores = data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something has gone wrong with the report!"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
*{
  --light-bg-color1: #f1f1f1;
  --light-bg-color2: #fafafa;
  --dark-bg-color1: #232328;
  --dark-bg-color2: #17171A;
}
.dark-bg-card{
  background-color: var(--dark-bg-color2) !important;
  border-radius: 10px;
}
.light-bg-card{
  background-color: var(--light-bg-color2) !important;
  border-radius: 10px;
}
.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  &.small {
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }
}
.item-number {
  font-size: 60px;
  font-weight: bold;
  font-family: sans-serif;
  &.small {
    font-size: 32px;
  }
}

.custom-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.small-border {
  border: 1px solid #acaeb1;
}
</style>
