<template>
  <b-table
    ref="refScoresList"
    :items="allScores"
    :fields="fields"
    primary-key="id"
    table-class="text-nowrap"
    responsive="sm"
    show-empty
    style="margin-bottom: 0px"
    :sticky-header="`${stickySize}vh`"
    :busy.sync="isBusy"
    class="table-new-customization custom-table"
  >
    <template #table-busy>
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle mr-1" />
        <strong>Loading ...</strong>
      </div>
    </template>

    <template #cell(date)="data">
      {{ data.item.date | myGlobal }}
    </template>
    <template #cell(file)="data">
      <div class="d-flex justify-content-center align-items-center">
        <a
          v-if="data.item.route_pdf != null"
          :href="data.item.route_pdf"
          target="_blanck"
        >
          <amg-icon size="18" class="text-danger" icon="FilePdfIcon" />
        </a>
        <span v-else>-- </span>
      </div>
    </template>

    <template #cell(cr)="data">
      <router-link
        v-if="data.item.state == 1"
        target="_blank"
        :to="{
          name: 'ce-customer-service-report-lead',
          params: {
            idfile: data.item.score_id,
            idlead: data.item.lead_id,
            accountId: client.id,
            modul: 25,
          },
        }"
      >
        <feather-icon icon="FileTextIcon" size="15" />
      </router-link>
    </template>
    <template #cell(equifax)="data">
      <div>
        <span
          style="
            width: 20px;
            color: #981e32;
            font-weight: bold;
            font-size: 15px;
            border-top: none;
            text-align: center;
          "
          >{{ data.item.equifax }}
        </span>
      </div>
    </template>

    <template #cell(experian)="data">
      <span
        style="
          color: #0566b7;
          font-weight: bold;
          font-size: 15px;
          border-top: none;
          text-align: center;
        "
        >{{ data.item.experian }}
      </span>
    </template>

    <template #cell(trans_union)="data">
      <span
        style="
          color: #4d917a;
          font-weight: bold;
          font-size: 15px;
          border-top: none;
          text-align: center;
        "
        >{{ data.item.transunion }}
      </span>
    </template>
    <template #cell(deleted_ac)="data">
      {{ `${data.item.deleted_ac} / ${data.item.updated_ac}` }}
    </template>
  </b-table>
</template>

<script>
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
export default {
  props: {
    lead_id: {
      type: Number,
      required: true,
    },
    stickySize: {
      type: String,
      default: "21",
    },
  },
  data() {
    return {
      isBusy: false,
      allScores: [],
      fields: [
        {
          key: "date",
          label: "Date",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "file",
          label: "File",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   key: "negative_ac",
        //   label: "Negative",
        //   tdClass: "text-center text-danger font-weight-bolder",
        //   thClass: "text-center",
        // },
        // {
        //   key: "in_dispute_ac",
        //   label: "In Dispute",
        //   tdClass: "text-center text-warning font-weight-bolder",
        //   thClass: "text-center",
        // },
        // {
        //   key: "deleted_ac",
        //   label: "Deleted / Updated",
        //   tdClass: "text-center text-success font-weight-bolder",
        //   thClass: "text-center",
        // },
        {
          key: "equifax",
          label: "Equifax",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "experian",
          label: "Experian",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "trans_union",
          label: "Trans Union",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    this.getScores();
  },
  methods: {
    async getScores() {
      try {
        this.isBusy = true;
        const data = await ClientDashboardService.allScore({
          id: this.lead_id,
        });
        if (data.status === 200) {
          this.allScores = data.data;
        }
        return this.allScores;
      } catch (error) {
        console.error(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with score!"
        );
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped>
  .custom-table >>> th {
    background-color: #3f7afa !important;
    color: white !important;
  }
  .custom-table >>> th:first-child {
    border-top-left-radius: 10px;
  }
  .custom-table >>> th:last-child {
    border-top-right-radius: 10px;
  }
</style>