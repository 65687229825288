<template>
  <div class="container-grid mb-2 px-1" :class="{ small: small }">
    <div
      class="shadow w-auto rounded text-center"
      :class="small ? 'small-border' : 'p-3'"
    >
      <div
        :style="small ? 'height: 32px' : 'height: 64px'"
        class="d-flex align-items-center justify-content-center"
      >
        <b-img
          :src="'/assets/images/icons/bureaus/transunion.png'"
          :width="small ? 64 : 98"
        />
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <h2 class="item-number" :class="{ small: small }">
          {{
            newScores.transunion == "Score Not Reported"
              ? "N/A"
              : newScores.transunion
          }}
        </h2>
        <feather-icon
          :icon="
            oldScores.transunion < newScores.transunion
              ? 'TrendingUpIcon'
              : 'TrendingDownIcon'
          "
          v-if="oldScores"
          :size="small ? '18' : '28'"
          class="ml-1"
          :class="{
            'text-success': oldScores.transunion < newScores.transunion,
            'text-danger': oldScores.transunion > newScores.transunion,
          }"
          v-b-tooltip.hover.top="`Last Score: ${oldScores.transunion}`"
        />
      </div>
    </div>
    <div
      class="shadow w-auto rounded text-center"
      :class="small ? 'small-border' : 'p-3'"
    >
      <div
        :style="small ? 'height: 32px' : 'height: 64px'"
        class="d-flex align-items-center justify-content-center"
      >
        <b-img
          :src="'/assets/images/icons/bureaus/experian.png'"
          :width="small ? 64 : 98"
        />
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <h2 class="item-number" :class="{ small: small }">
          {{
            newScores.experian == "Score Not Reported"
              ? "N/A"
              : newScores.experian
          }}
        </h2>
        <feather-icon
          :icon="
            oldScores.experian < newScores.experian
              ? 'TrendingUpIcon'
              : 'TrendingDownIcon'
          "
          v-if="oldScores"
          :size="small ? '18' : '28'"
          class="ml-1"
          :class="{
            'text-success': oldScores.experian < newScores.experian,
            'text-danger': oldScores.experian > newScores.experian,
          }"
          v-b-tooltip.hover.top="`Last Score: ${oldScores.experian}`"
        />
      </div>
    </div>
    <div
      class="shadow w-auto rounded text-center"
      :class="small ? 'small-border' : 'p-3'"
    >
      <div
        :style="small ? 'height: 32px' : 'height: 64px'"
        class="d-flex align-items-center justify-content-center"
      >
        <b-img
          :src="'/assets/images/icons/bureaus/equifax.png'"
          :width="small ? 64 : 98"
        />
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <h2 class="item-number" :class="{ small: small }">
          {{
            newScores.equifax == "Score Not Reported"
              ? "N/A"
              : newScores.equifax
          }}
        </h2>
        <feather-icon
          :icon="
            oldScores.equifax < newScores.equifax
              ? 'TrendingUpIcon'
              : 'TrendingDownIcon'
          "
          v-if="oldScores"
          :size="small ? '18' : '28'"
          class="ml-1"
          :class="{
            'text-success': oldScores.equifax < newScores.equifax,
            'text-danger': oldScores.equifax > newScores.equifax,
          }"
          v-b-tooltip.hover.top="`Last Score: ${oldScores.equifax}`"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ClientReportService from "@/views/commons/components/clients/dashboard/services/client.report.js";
export default {
  data() {
    return {
      showAllReports: false,
      dataScores: [],
      iconIsDown: false,
    };
  },
  props: {
    clientId: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    await this.getClientReport();
  },
  computed: {
    newScores() {
      return this.dataScores[0] || null;
    },
    oldScores() {
      return this.dataScores[1] || null;
    },
  },
  methods: {
    async getClientReport() {
      try {
        this.loading = true;
        let params = {
          idClientAccount: this.$route.params.idClient || this.clientId,
        };
        const { data } = await ClientReportService.getScores(params);
        this.dataScores = data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something has gone wrong with the report!"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  &.small {
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }
}
.item-number {
  font-size: 60px;
  font-weight: bold;
  font-family: sans-serif;
  &.small {
    font-size: 32px;
  }
}

.custom-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.small-border {
  border: 1px solid #acaeb1;
}
</style>
